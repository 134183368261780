var bpmn = require('bpmn-js/lib/Modeler');
var minimap = require('diagram-js-minimap');
var resizeElement =require('./lib/resize');
var colorpicker = require('./lib/color-picker');
var propertiesPanelModule = require('bpmn-js-properties-panel');
var propertiesProviderModule = require('bpmn-js-properties-panel/lib/provider/camunda');
var camundaModdleDescriptor = require('camunda-bpmn-moddle/resources/camunda');
var style = require('bpmn-js/dist/assets/diagram-js.css');
var icons = require('bpmn-font/dist/css/bpmn-embedded.css');
var minimap_css = require('diagram-js-minimap/assets/diagram-js-minimap.css');
var props_css = require('bpmn-js-properties-panel/dist/assets/bpmn-js-properties-panel.css');

function getBpmnModule() {
    return bpmn;
}

function getEmptyBpmn() {
    return `
    <?xml version="1.0" encoding="UTF-8"?>
    <bpmn2:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd" id="sample-diagram" targetNamespace="http://bpmn.io/schema/bpmn">
    <bpmn2:process id="Process_1" isExecutable="false">
        <bpmn2:startEvent id="StartEvent_1"/>
    </bpmn2:process>
    <bpmndi:BPMNDiagram id="BPMNDiagram_1">
        <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1">
        <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
            <dc:Bounds height="36.0" width="36.0" x="412.0" y="240.0"/>
        </bpmndi:BPMNShape>
        </bpmndi:BPMNPlane>
    </bpmndi:BPMNDiagram>
    </bpmn2:definitions>`;
}

function getMiniMapModule() {
    return minimap.default;
}

function getColorPickerModule() {
    return colorpicker.default;
}

function getResizeElementModule() {
    return resizeElement.default;
}

function getPropertiesPanelModule() {
    return propertiesPanelModule;
}

function getPropertiesProviderModule() {
    return propertiesProviderModule;
}

function getCamundaModdleDescriptorModule() {
    return camundaModdleDescriptor;
}

module.exports = {
    getBpmnModule,
    getEmptyBpmn,
    getMiniMapModule,
    getColorPickerModule,
    getResizeElementModule,
    getPropertiesPanelModule,
    getPropertiesProviderModule,
    getCamundaModdleDescriptorModule
}